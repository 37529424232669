import classNames from "classnames"
import React from "react"
import { motion } from "framer-motion"

import styled from "styled-components"
import PlayShowReel from "src/components/animations/PlayShowReel"

import PrismButton from "src/components/common/PrismButton"
import { Nav } from "src/components/common"
import { device } from "src/utils"
import { useRecoilState } from "recoil"
import { homeThemeState } from "src/atoms"

const homeText = {
  design: {
    title: "crafting clever brands & immersive experiences",
    values: ["brand identity", "web design", "animation & video", "UI/UX"],
  },
  lab: {
    title: "launching interactive, multi-sensory worlds",
    values: ["3D modeling", "world building", "immersive", "custom audio"],
  },
}

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.6,
    },
  },
}

const list = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const item = {
  hidden: { opacity: 0, y: -20 },
  show: { opacity: 1, y: 0 },
}
const listItem = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
}

// const toggleDelay = {
//   initial: { opacity: 0, y: -20 },
//   animate: { opacity: 1, y: 0 },
//   transition: {
//     delay: 0.7,
//   },
// }

const HomeWave = () => {
  const [theme, setTheme] = useRecoilState(homeThemeState)

  const title = homeText[theme].title
  const values = homeText[theme].values

  const renderValues = () =>
    values.map((value) => (
      <motion.li key={value} variants={listItem}>
        {value}
      </motion.li>
    ))

  return (
    <Background data-theme={theme}>
      <Nav dark={theme === "lab"} />
      <StyledHomeWave>
        <motion.div
          className="animated-container"
          variants={container}
          initial="hidden"
          animate="show"
          key={theme}
        >
          <div className="spacer" />
          <div className="container">
            <motion.div className="toggle">
              <button
                className={classNames({ active: theme === "design" })}
                onClick={() => setTheme("design")}
              >
                <span>design studio</span>
              </button>
              <button
                onClick={() => setTheme("lab")}
                className={classNames({ active: theme === "lab" })}
              >
                <span>innovation lab</span>
              </button>
            </motion.div>
            <motion.h1 className="title" variants={item}>
              {title}
            </motion.h1>
            <motion.ul className="values" variants={list}>
              {renderValues()}
            </motion.ul>
          </div>
          <motion.div
            className="bottom"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <PrismButton />
          </motion.div>
        </motion.div>
        <motion.div className="showWrap" variants={item}>
          <PlayShowReel />
        </motion.div>
      </StyledHomeWave>
    </Background>
  )
}

const Background = styled.section`
  --wave-background: #f9f9f9;
  --title-color: #181818;
  --values-color: #676767;
  --toggle-color-active: white;
  --toggle-color-inactive: #c4c4c4;
  --toggle-background-active: #181818;

  &[data-theme="lab"] {
    /* --wave-background: #101010; */
    --wave-background: black;
    --title-color: #ffffff;
    --values-color: #ababab;
    --toggle-color-active: #181818;
    --toggle-color-inactive: #676767;
    --toggle-background-active: #fff;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  background: var(--wave-background);

  height: 100%;
  overflow: hidden;
`

const StyledHomeWave = styled(motion.div)`
  /* min-height: calc(var(--vh, 1vh) * 100); */

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
  flex: 1;

  padding: 20vh 8px 0px;
  @media ${device.laptop} {
    padding: 30vh 16px 0px;
  }

  .animated-container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;

    @media ${device.laptop} {
      padding-top: 0;
    }

    h1.title {
      max-width: 400px;
      @media ${device.laptop} {
        max-width: 800px;
      }
    }
  }

  .bottom {
    padding: 0 var(--sp-16);
    margin: 0 auto;
    margin-top: var(--sp-48);
  }

  .buttonB {
    /* height: 60px;
      width: 200px; */

    height: 100px;
    width: 300px;
    padding: 0;
  }

  .container {
    position: relative;
    z-index: 5;
  }

  .toggle {
    display: flex;
    justify-content: center;
    grid-gap: var(--sp-8);

    @media ${device.mobileM} {
      grid-gap: var(--sp-16);
    }

    @media ${device.laptop} {
      grid-gap: var(--sp-24);
    }

    button {
      color: var(--toggle-color-inactive);
      font-family: "PP Mori";
      cursor: pointer;
      position: relative;
      padding: 12px;

      overflow: hidden;

      span {
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: -0.02em;
        position: relative;
        z-index: 2;
        line-height: 1;
      }

      &::before {
        content: "";
        background: var(--toggle-background-active);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        z-index: 1;
        transition-property: width;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        width: 0%;
      }
      &.active {
        color: var(--toggle-color-active);
        &::before {
          width: 100%;
        }
      }

      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
  }

  .title {
    text-align: center;
    margin: 0 auto;
    margin-top: var(--sp-16);
    color: var(--title-color);

    max-width: 500px;
    @media ${device.laptop} {
      max-width: 900px;
    }
  }

  .values {
    display: flex;
    justify-content: center;
    margin-top: var(--sp-24);

    grid-gap: var(--sp-8);
    flex-direction: column;
    align-items: center;

    @media ${device.laptop} {
      grid-gap: var(--sp-64);
      flex-direction: row;
    }

    li {
      color: var(--values-color);
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  .showWrap {
    position: absolute;
    right: 60px;
    bottom: 40px;
    z-index: 2;

    display: none;

    @media ${device.laptop} {
      display: inline-block;
    }
  }
`

export default HomeWave
